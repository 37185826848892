import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { NavigationSidebarMenu } from '@modules/root/model/navigation-sidebar-menu.interface';
import { IMAILTASTIC_AUTHORIZATION_SCOPE } from '@services/account/account-service.interface';
import { NavigationSidebarService } from '@services/navigation-sidebar/navigation-sidebar.service';
import { BehaviorSubject } from 'rxjs';

const ADVANCE_SETTINGS_MENU: NavigationSidebarMenu[] = [
  { image: 'assets/images/sidebar/account.svg', name: 'administration', url: '/settings/administration' },
  {
    image: 'assets/images/sidebar/payment.svg',
    name: 'nav_plan_and_billing',
    isDropdownOpen: true,
    submenu: [
      { name: 'overview', url: 'booking/overview' },
      { name: 'PAYMENTINFORMATION', url: 'booking/payment-information' },
      { name: 'subscriptionbills', url: 'booking/invoices' }
    ]
  },
  // {
  //   image: 'assets/images/sidebar/payment.svg',
  //   name: 'nav_plan_and_billing',
  //   url: 'booking/overview'
  // },
  {
    image: 'assets/images/sidebar/settings.svg',
    name: 'advancedsettings',
    isDropdownOpen: true,
    submenu: [
      { name: 'EMAIL_NOTIFICATIONS', url: 'settings/advancedsettings/emailnotification' },
      { name: 'adv_stngs_internal_domains_hl', url: 'settings/advancedsettings/internaldomains' },
      { name: 'tracking', url: 'settings/advancedsettings/domains' },
      { name: 'displaysettings', url: 'settings/advancedsettings/displaysettings' },
      { name: 'Public API', url: 'settings/advancedsettings/publicapi' },
      { name: 'prio_headline', url: 'settings/advancedsettings/campaignpriority' },
      { name: 'utm_settings', url: 'settings/advancedsettings/utm' }
    ]
  }
];

@Component({
  selector: 'mt-navigation-sidebar-settings-submenu',
  templateUrl: './navigation-sidebar-settings-submenu.component.html',
  styleUrls: ['./navigation-sidebar-settings-submenu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationSidebarSettingsSubmenuComponent {
  navigationSidebarService = inject(NavigationSidebarService);

  /**
   * Holds the current settings menu state
   */
  private _settingsMenu$ = new BehaviorSubject(ADVANCE_SETTINGS_MENU);

  /**
   * Toggle dropdown of plans
   * @defaultValue true
   */
  @Input() isPlanAndBillingDropdown = true;

  /**
   * Sets user has subscription status
   */
  @Input() hasSubscription!: boolean | null;

  /**
   * Get authorization scope to identify the plan information
   */
  @Input() authorizationScope!: IMAILTASTIC_AUTHORIZATION_SCOPE;

  /**
   * Emits the current settings menu state
   */
  settingsMenu$ = this._settingsMenu$.asObservable();

  /**
   * Sets new menu items
   * @param menuItems - New menu items to set
   */
  set menus(menuItems: NavigationSidebarMenu[]) {
    this._settingsMenu$.next(menuItems);
  }
}
